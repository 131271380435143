* {
    box-sizing: border-box;
}

html, body, #root, .App {
    height: 100%;
}

body {
    margin: 0;
    padding: 0;
    overflow: hidden;
}
